@import 'mixins';

.footer {

    // .footer_wrapper
    &_wrapper {
        display: flex;
        max-width: 100%;
        flex-direction: column;
        background-color: #3d4045;
        border-top: 5px solid $uwkc-orange;
        overflow: hidden;
    }

    // .footer_container
    &_container {
        max-width: map_get($breakpoints, 'uwkc-max');
        width: 100%;
        margin: 0 auto;
        padding: 0 2rem;
        color: #808080;
    }

    // .footer_row
    &_row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -2rem;
        width: 100%;

        @include mq('tablet', min) {
            flex-wrap: nowrap;
        }
    }

    // .footer_column
    &_column {
        flex-basis: 50%;
        padding: 4rem 2rem;
        flex-grow: 1;

        p {
            color: #fff;
        }

        & > * {
            margin-bottom: 2rem;
            margin-top: 0;

            &:last-child {
                margin-bottom: 0;
            }
        }

        p a, a, a:visited, p a:visited, li a, li a:visited {
            color: $uwkc-white;
            text-decoration: none;
            &:hover, &:focus{
                text-decoration: underline;
            }
            &:focus{
                @include focus-outline-reversed;
            }
        }

        @include mq('phablet', 'min') {
            flex-basis: 25%;
        }
    }

    // .footer_title
    &_title {
        margin-bottom: 2rem;
        font-family: $font-TG;
        color:lighten($uwkc-blue-light, 15%);
        font-size: 2.8rem;
        font-weight: normal;
    }

    // .footer_social_container
    &_social {
        
        &_container {
            display: flex;
            margin: 0 -1rem 2rem -1rem;
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        &_icon {
            padding: 0 1rem 2rem 1rem;

            a, a:visited{
                display: block;
                width: 4.5rem;
                height: 4.5rem;
                background-color: $uwkc-orange;
                transition: background-color ease .3s;
                color: #3d4045;
                text-align: center;
                font-size: 3rem;
                border-radius: 2px;
                box-shadow: $drop-shadow;
                svg {
                    display: inline-block;
                    vertical-align: middle;
                }

                &:hover, &:focus {
                    background-color: #ebebeb;
                }
            }
        }
    }

    &_menu {

        &_wrapper {

        }

        &_container {

            li {
                list-style: none;
                font-size: 1.8rem;

                a {
                    
                    &:hover, &:focus {
                        color: $uwkc-orange;
                        text-decoration: underline;
                    }
                }

                & + li {
                    padding-top: .2em;
                }
            }
        }
    }
}

.copyright {
    font-size: 12px;
    text-align: center;
    color: #fff;
}

.philanthropy_nw_icon {
    text-align: center;

    img {
        filter: grayscale(100%);
        transition: filter ease .3s;

        &:hover {
            filter: grayscale(0);
        }
    }
}