@media print {
    #site_header {
        display: none;
    }

    #footer_wrapper {
        display: none;
    }

    aside {
        display: none;
    }
}