@import 'mixins';

.signup_form {
    position: relative;

    &_container {
        position: relative;
    }

    .d-flex {
        input {
            width: 75%;
        }
        
        button {
            margin-top: 0;
            border-radius: 0;
            width: 25%;
            border: none;
        }
    }

    input, select {
        width: 100%;
        display: block;
        padding: .8em 1em;
        color: #767676;
        outline: none;
        width: 100%;
        background-color: #ddd;
        border: none;
        font-size: 20px;

        & + input {
            margin-top: 1rem;
        }

        &[type="submit"] {
            display: inline-block;
            width: auto;
            padding: .6em 1.3em;
            background-color: $uwkc-blue;
            color: $uwkc-white;
            border:3px solid $uwkc-blue;
            border-radius: 5px;
            margin-top: 3rem;
            box-shadow: $drop-shadow;
            &:hover, &:focus{
                background-color: $uwkc-white;
                color: $uwkc-blue;
                border-style:double;
            }
            &:focus{
                @include focus-outline();
            }
        }

    }

    select {
        -webkit-appearance: none;
    }

    &_row {
        margin: 0 -2rem;
        display: flex;
        flex-wrap: wrap;

        .signup_form_column {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

    &_column {
        width: 100%;
        margin-bottom: 0;

        &--half {
            @include mq(tablet-small, min) {
                width: 50%;
            }
        }

        &--fourth {
            width: 50%;

            @include mq(tablet-small, min) {
                width: 25%;
            }
        }

        &--submit {
            margin-top: 3rem;
        }
    }

    &_label {
        display: block;
        margin-top: 3rem;
        font-size: calc(18px + 2 * ((100vw - 320px) / 880));
        line-height: 1.5;
    }

    button {
        cursor: pointer;
    }
}